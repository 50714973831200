import {
  Availability,
  BusinessActionArea,
  BusinessActionResultStatus,
  BusinessActionStatus,
  CommunicationStatus,
  CommunicationType,
  CurrencyCodes,
  GanttTimeSectionAggregation,
  InventoryChangeType,
  MaterialDemandQuantityType,
  MaterialOrigin,
  MeasurementUnit,
  ModelInstanceStatus,
  OrderLineGroupStatus,
  OrderLineStatus,
  OrderReleaseStatus,
  OrderType,
  OrganizationType,
  PeriodAggregationUnits,
  PlanningStatus,
  ProcessDraftStatus,
  QuotationType,
  ResourceCapacityChangeType,
  ResourceCapacityEntrySource,
  ResourceCapacityGroup,
  ResourcePurpose,
  ResourceSubType,
  ResourceTrackingType,
  ResourceType,
  ScenarioStatus,
  StepResourceAssignmentType,
  StepResourceUsageType,
  StepType,
  TaskSubType,
  TemplateStatus,
  TotalStatus,
  UserNoticeStatus,
  UserNoticeType,
  WorkJournalRecordEventAction,
  WorkJournalRecordEventType
} from "@masta/generated-model";
import { $t, Locale } from "@/i18n";

export const defaultUndefinedKey = "Unknown";
export const emptyKey = "Empty";

const localeTranslations = new Map<Locale, string>([
  [Locale.English, "English"],
  [Locale.Polish, "Polski"],
  [Locale.Ukrainian, "український"],
  [Locale.German, "Deutsch"],
  [Locale.Spanish, "Español"],
  [Locale.French, "Français"],
  [Locale.Portuguese, "Português"],
  [Locale.Czech, "Česky"],
  [Locale.Slovak, "Slovenská"],
  [Locale.Turkish, "Türkçe"],
  [Locale.ChineseSimplified, "中文"]
]);

export function translateLocale(t: Locale): string {
  return localeTranslations.get(t) ?? defaultUndefinedKey;
}

// $t("resource-type-Person-label", {$: "Person"})
// $t("resource-type-PersonGroup-label", {$: "Person Group"})
// $t("resource-type-Agreement-label", {$: "Agreement"})
// $t("resource-type-AgreementGroup-label", {$: "Agreement Group"})
// $t("resource-type-Calendar-label", {$: "Calendar"})
// $t("resource-type-Equipment-label", {$: "Equipment"})
// $t("resource-type-EquipmentGroup-label", {$: "Equipment Group"})
// $t("resource-type-Material-label", {$: "Material"})
// $t("resource-type-MaterialGroup-label", {$: "Material Group"})
// $t("resource-type-Location-label", {$: "Location"})
// $t("resource-type-Asset-label", {$: "Asset"})
// $t("resource-type-Service-label", {$: "Service"})
export function translateResourceType(t: ResourceType): string {
  if (typeof t === "number") {
    const key = ResourceType[t];
    return $t(`resource-type-${key}-label`);
  }
  return "";
}

// $t("task-subType-Undefined-label", { $: "Undefined" })
// $t("task-subType-Epic-label", { $: "Epic" })
// $t("task-subType-Bug-label", { $: "Bug" })
// $t("task-subType-Support-label", { $: "Support" })
// $t("task-subType-DevelopmentTask-label", { $: "Development Task" })
// $t("task-subType-Subtask-label", { $: "Subtask" })
// $t("task-subType-WorkOrder-label", { $: "Work Order" })
// $t("task-subType-WorkOrderAlternative-label", { $: "Work Order Alternative" })
// $t("task-subType-ProductionTask-label", { $: "Production Task" })
// $t("task-subType-InterruptTask-label", { $: "Interrupt Task" })
// $t("task-subType-ReceiptOfMaterial-label", { $: "Receipt Of Material" })
export function translateTaskSubType(t: TaskSubType): string {
  const key = TaskSubType[t] ?? TaskSubType[TaskSubType.Undefined];
  return $t(`task-subType-${key}-label`);
}

// $t("measurement-unit-Unknown-label", {$: "Unknown"})
// $t("measurement-unit-Piece-label", {$: "pcs."})
// $t("measurement-unit-Kilometer-label", {$: "km"})
// $t("measurement-unit-Meter-label", {$: "m"})
// $t("measurement-unit-Centimeter-label", {$: "cm"})
// $t("measurement-unit-Millimeter-label", {$: "mm"})
// $t("measurement-unit-Mile-label", {$: "mi"})
// $t("measurement-unit-Yard-label", {$: "yd"})
// $t("measurement-unit-Foot-label", {$: "ft"})
// $t("measurement-unit-Inch-label", {$: "in"})
// $t("measurement-unit-MetricTonne-label", {$: "t"})
// $t("measurement-unit-Kilogram-label", {$: "kg"})
// $t("measurement-unit-Gram-label", {$: "g"})
// $t("measurement-unit-ImperialTon-label", {$: "T"})
// $t("measurement-unit-Pound-label", {$: "lb."})
// $t("measurement-unit-Ounce-label", {$: "oz"})
// $t("measurement-unit-Litre-label", {$: "l"})
// $t("measurement-unit-Decilitre-label", {$: "dl"})
// $t("measurement-unit-Centilitre-label", {$: "cl"})
// $t("measurement-unit-Millilitre-label", {$: "ml"})
// $t("measurement-unit-Gallon-label", {$: "gal"})
// $t("measurement-unit-Pint-label", {$: "pt"})
// $t("measurement-unit-FluidOunce-label", {$: "fl. oz"})
// $t("measurement-unit-SquareKilometer-label", {$: "sq. km"})
// $t("measurement-unit-SquareMeter-label", {$: "sq. m"})
// $t("measurement-unit-SquareCentimeter-label", {$: "sq. cm"})
// $t("measurement-unit-SquareMiles-label", {$: "sq. mi"})
// $t("measurement-unit-SquareYards-label", {$: "sq. yd"})
// $t("measurement-unit-SquareFoot-label", {$: "sq. ft"})
// $t("measurement-unit-SquareInch-label", {$: "sq. in"})
// $t("measurement-unit-Seconds-label", {$: "sec"})
// $t("measurement-unit-Minutes-label", {$: "min"})
// $t("measurement-unit-Hours-label", {$: "hr"})
// $t("measurement-unit-Days-label", {$: "day"})
// $t("measurement-unit-Weeks-label", {$: "week"})
// $t("measurement-unit-Months-label", {$: "months"})
// $t("measurement-unit-Years-label", {$: "years"})
// $t("measurement-unit-FTE-label", {$: "FTE"})
export function translateMeasurementUnit(mu: MeasurementUnit): string {
  if (typeof mu === "number") {
    const key = MeasurementUnit[mu];
    return $t(`measurement-unit-${key}-label`);
  }
  return "";
}

// $t("resourceCapacity-changeType-Absolute-label", {$: "Absolute"})
// $t("resourceCapacity-changeType-Relative-label", {$: "Relative"})
export function translateResourceCapacityChangeType(ct: ResourceCapacityChangeType): string {
  if (typeof ct === "number") {
    const key = ResourceCapacityChangeType[ct];
    return $t(`resourceCapacity-changeType-${key}-label`);
  }
  return "";
}

// $t("task-assigmentType-Alternatives-label", { $: "Alternatives" })
// $t("task-assigmentType-ByCapabilities-label", { $: "By capabilities" })
// $t("task-assigmentType-Generic-label", { $: "Generic" })
export function translateAssignmentType(t: StepResourceAssignmentType): string {
  const key = StepResourceAssignmentType[t] ?? defaultUndefinedKey;
  return $t(`task-assigmentType-${key}-label`);
}

// $t("task-usageType-Unknown-label", { $: "Unknown" })
// $t("task-usageType-Book-label", { $: "Book" })
// $t("task-usageType-Consume-label", { $: "Consume" })
// $t("task-usageType-Produce-label", { $: "Produce" })
// $t("task-usageType-Scrap-label", { $: "Scrap" })
// $t("task-usageType-ByProduct-label", { $: "By-Product" })
export function translateUsageType(t: StepResourceUsageType): string {
  const key = StepResourceUsageType[t] ?? defaultUndefinedKey;
  return $t(`task-usageType-${key}-label`);
}

// $t("task-inventoryChangeType-Default-label", { $: "At Start Or End" })
// $t("task-inventoryChangeType-AfterSpecificAmount-label", { $: "After Specific Amount" })
// $t("task-inventoryChangeType-Proportional-label", { $: "Proportional" })
export function translateInventoryChangeType(t: InventoryChangeType): string {
  const key = InventoryChangeType[t] ?? defaultUndefinedKey;
  return $t(`task-inventoryChangeType-${key}-label`);
}

// $t("task-stepType-Undefined-label", { $: "Undefined" })
// $t("task-stepType-Changeover-label", { $: "Changeover" })
// $t("task-stepType-Production-label", { $: "Production" })
// $t("task-stepType-Receiving-label", { $: "Receiving" })
// $t("task-stepType-QualityCheck-label", { $: "Quality Check" })
// $t("task-stepType-TearDown-label", { $: "Tear Down" })
// $t("task-stepType-Transport-label", { $: "Transport" })
// $t("task-stepType-Rework-label", { $: "Rework" })
// $t("task-stepType-Interrupt-label", { $: "Interrupt" })
// $t("task-stepType-Maintenance-label", { $: "Maintenance" })
export function translateStepType(t: StepType): string {
  const key = StepType[t] ?? defaultUndefinedKey;
  return $t(`task-stepType-${key}-label`);
}

// $t("resource-trackingType-Unknown-label", { $: "None" })
// $t("resource-trackingType-None-label", { $: "None" })
// $t("resource-trackingType-Lot-label", { $: "Lot" })
// $t("resource-trackingType-Serial-label", { $: "Serial" })
export function translateTrackingType(t: ResourceTrackingType): string {
  const key = ResourceTrackingType[t] ?? defaultUndefinedKey;
  return $t(`resource-trackingType-${key}-label`);
}

// $t("resource-subType-Unknown-label", { $: "Unknown" })
// $t("resource-subType-FTE-label", { $: "FTE" })
// $t("resource-subType-BudgetManualProgress-label", { $: "Budget Manual Progress" })
// $t("resource-subType-BudgetAutomatedProgress-label", { $: "Budget Automated Progress" })
// $t("resource-subType-MaterialScrap-label", { $: "Scrap" })
// $t("resource-subType-MaterialConsumable-label", { $: "Consumable" })
// $t("resource-subType-MaterialSemiFinishedOrFinalProduct-label", { $: "Semi-Finished / Final Product" })
// $t("resource-subType-MaterialRawOrSubcomponent-label", { $: "Raw Material / Component" })
// $t("resource-subType-AssetIdentity-label", { $: "Identity" })
export function translateResourceSubType(t: ResourceSubType): string {
  const key = ResourceSubType[t] ?? defaultUndefinedKey;
  return $t(`resource-subType-${key}-label`);
}

// $t("resource-purpose-Production-label", { $: "Production" })
// $t("resource-purpose-Purchase-label", { $: "Purchase" })
// $t("resource-purpose-Sale-label", { $: "Sale" })
// $t("resource-purpose-MakeToStock-label", { $: "Make To Stock" })
export function translateResourcePurpose(t: ResourcePurpose): string {
  const key = ResourcePurpose[t] ?? defaultUndefinedKey;
  return $t(`resource-purpose-${key}-label`);
}

// $t("productTemplate-templateStatus-Unknown-label", {$: "Unknown"})
// $t("productTemplate-templateStatus-WorkInProgress-label", {$: "Work in progress"})
// $t("productTemplate-templateStatus-Released-label", {$: "Released"})
// $t("productTemplate-templateStatus-Archived-label", {$: "Archived"})
export function translateTemplateStatus(t: TemplateStatus): string {
  const key = TemplateStatus[t] ?? defaultUndefinedKey;
  return $t(`productTemplate-templateStatus-${key}-label`);
}

// $t("modelInstance-status-Unknown-label", {$: "Unknown"})
// $t("modelInstance-status-WorkInProgress-label", {$: "Work in progress"})
// $t("modelInstance-status-Released-label", {$: "Released"})
// $t("modelInstance-status-Sent-label", {$: "Sent"})
// $t("modelInstance-status-Confirmed-label", {$: "Confirmed"})
// $t("modelInstance-status-Archived-label", {$: "Archived"})
export function translateModelInstanceStatus(t: ModelInstanceStatus): string {
  const key = ModelInstanceStatus[t] ?? defaultUndefinedKey;
  return $t(`modelInstance-status-${key}-label`);
}

// $t("order-orderType-Unknown-label", {$: "Unknown"})
// $t("order-orderType-Customer-label", {$: "Customer"})
// $t("order-orderType-InboundDelivery-label", {$: "Inbound Delivery"})
// $t("order-orderType-Purchase-label", {$: "Purchase"})
export function translateOrderType(t: OrderType): string {
  const key = OrderType[t] ?? defaultUndefinedKey;
  return $t(`order-orderType-${key}-label`);
}

export function translateCurrencyCode(c: CurrencyCodes): string {
  return CurrencyCodes[c]; //returns text value of enum (toString still returns the number)
}

// $t("order-orderReleaseStatus-New-label", {$: "New"})
// $t("order-orderReleaseStatus-InProgress-label", {$: "In Progress"})
// $t("order-orderReleaseStatus-Released-label", {$: "Released"})
// $t("order-orderReleaseStatus-Finished-label", {$: "Finished"})
export function translateOrderReleaseStatus(t: OrderReleaseStatus): string {
  const key = OrderReleaseStatus[t] ?? defaultUndefinedKey;
  return $t(`order-orderReleaseStatus-${key}-label`);
}

// $t("order-orderReleaseStatus-InPreparation-label", {$: "InPreparation"})
// $t("order-orderLineGroupStatus-InProgress-label", {$: "PreReleased"})
// $t("order-orderLineGroupStatus-Released-label", {$: "Released"})
export function translateOrderLineGroupStatus(t: OrderLineGroupStatus): string {
  const key = OrderLineGroupStatus[t] ?? defaultUndefinedKey;
  return $t(`order-orderReleaseStatus-${key}-label`);
}

// $t("order-orderLineStatus-Empty-label", {$: ""})
// $t("order-orderLineStatus-Unknown-label", {$: "Unknown"})
// $t("order-orderLineStatus-InPreparation-label", {$: "In Preparation"})
// $t("order-orderLineStatus-ReturnedToInPreparation-label", {$: "Returned To In Preparation"})
// $t("order-orderLineStatus-PreReleasedForQuotation-label", {$: "PreReleased For Quotation"})
// $t("order-orderLineStatus-ReleasedForQuotation-label", {$: "Released For Quotation"})
// $t("order-orderLineStatus-QuotationSent-label", {$: "Quotation Sent"})
// $t("order-orderLineStatus-Approved-label", {$: "Approved"})
// $t("order-orderLineStatus-Confirmed-label", {$: "Confirmed"})
// $t("order-orderLineStatus-Cancelled-label", {$: "Cancelled"})
// $t("order-orderLineStatus-Disputed-label", {$: "Disputed"})
// $t("order-orderLineStatus-Rejected-label", {$: "Rejected"})
// $t("order-orderLineStatus-Revised-label", {$: "Revised"})
// $t("order-orderLineStatus-PreReleased-label", {$: "Pre-Released"})
// $t("order-orderLineStatus-PreDelivery-label", {$: "Pre-Delivery"})
// $t("order-orderLineStatus-Released-label", {$: "Released"})
// $t("order-orderLineStatus-InDelivery-label", {$: "In Delivery"})
// $t("order-orderLineStatus-Finished-label", {$: "Finished"})
// $t("order-orderLineStatus-PreQuotation-label", {$: "PreQuotation"})
// $t("order-orderLineStatus-Quotation-label", {$: "Quotation"})
export function translateOrderLineStatus(t: OrderLineStatus): string {
  const key = OrderLineStatus[t] ?? emptyKey;
  return $t(`order-orderLineStatus-${key}-label`);
}

// $t("calendar-availability-Unknown-label", {$: "Unknown"})
// $t("calendar-availability-NotAvailable-label", {$: "Not Available"})
// $t("calendar-availability-Available-label", {$: "Available"})
export function translateAvailability(t: Availability): string {
  const key = Availability[t] ?? defaultUndefinedKey;
  return $t(`calendar-availability-${key}-label`);
}

// $t("task-totalStatus-Unknown-label", {$: "Unknown"})
// $t("task-totalStatus-NotSelected-label", {$: "Not Selected"})
// $t("task-totalStatus-NotScheduled-label", {$: "Not Scheduled"})
// $t("task-totalStatus-PartiallyScheduled-label", {$: "Partially Scheduled"})
// $t("task-totalStatus-Scheduled-label", {$: "Scheduled"})
// $t("task-totalStatus-Released-label", {$: "Released"})
// $t("task-totalStatus-NotStarted-label", {$: "Not Started"})
// $t("task-totalStatus-Interrupted-label", {$: "Interrupted"})
// $t("task-totalStatus-Running-label", {$: "Running"})
// $t("task-totalStatus-Finished-label", {$: "Finished"})
export function translateTotalStatus(t: TotalStatus): string {
  const key = TotalStatus[t] ?? defaultUndefinedKey;
  return $t(`task-totalStatus-${key}-label`);
}

// $t("task-planningStatus-Planned-label", {$: "Confirmed"})
// $t("task-planningStatus-Cancelled-label", {$: "Cancelled"})
// $t("task-planningStatus-Quotation-label", {$: "Quotation"})
export function translatePlanningStatus(t: PlanningStatus): string {
  const key = PlanningStatus[t] ?? defaultUndefinedKey;
  return $t(`task-planningStatus-${key}-label`);
}

// $t("organization-type-Unknown-label", {$: "Unknown"})
// $t("organization-type-Supplier-label", {$: "Supplier"})
// $t("organization-type-Customer-label", {$: "Customer"})
// $t("organization-type-Subcontractor-label", {$: "Subcontractor"})
// $t("organization-type-InternalCustomer-label", {$: "Internal Customer"})
export function translateOrganizationType(t: OrganizationType): string {
  const key = OrganizationType[t] ?? defaultUndefinedKey;
  return $t(`organization-type-${key}-label`);
}

// $t("scenario-status-Production-label", {$: "Production"})
// $t("scenario-status-Simulation-label", {$: "Simulation"})
// $t("scenario-status-Archived-label", {$: "Archived"})
export function translateScenarioStatus(t: ScenarioStatus): string {
  const key = ScenarioStatus[t] ?? defaultUndefinedKey;
  return $t(`scenario-status-${key}-label`);
}

// $t("processDraft-status-WorkInProgress-label", {$: "Work In Progress"})
// $t("processDraft-status-Archived-label", {$: "Archived"})
// $t("processDraft-status-WaitingForAi-label", {$: "Waiting for AI"})
export function translateProcessDraftStatus(t: ProcessDraftStatus): string {
  const key = ProcessDraftStatus[t] ?? defaultUndefinedKey;
  return $t(`processDraft-status-${key}-label`);
}

// $t("workJournal-eventType-Unknown-label", {$: "Unknown"})
// $t("workJournal-eventType-Duration-label", {$: "Reported Duration"})
// $t("workJournal-eventType-Production-label", {$: "Reported Production"})
// $t("workJournal-eventType-Finish-label", {$: "Reported Finish"})
// $t("workJournal-eventType-MachineInterruptStart-label", {$: "Reported Machine Interrupt"})
// $t("workJournal-eventType-Quantity-label", {$: "Reported Quantity"})
// $t("workJournal-eventType-ResourceResourceAssignment-label", {$: "Reported Resources Assignment"})
// $t("workJournal-eventType-QualityCheck-label", {$: "Reported Process Parameters"})
// $t("workJournal-eventType-ClockIn-label", {$: "Reported Clock In"})
// $t("workJournal-eventType-ClockOut-label", {$: "Reported Clock Out"})
// $t("workJournal-eventType-TaskInterruptStart-label", {$: Reported Task Interrupt"})
// $t("workJournal-eventType-ProductionCommand-label", {$: "Reported Production"})
// $t("workJournal-eventType-FinishCommand-label", {$: "Reported Finish"})
// $t("workJournal-eventType-InterruptCommand-label", {$: "Reported Interruption"})
// $t("workJournal-eventType-MachineDataElectricityConsumption-label", {$: "Reported Machine Data Electricity Consumption"})
// $t("workJournal-eventType-UnplannedQualityCheck-label", {$: "Reported Unplanned Quality Check"})

export function translateWorkJournalRecordEventType(t: WorkJournalRecordEventType): string {
  const key = WorkJournalRecordEventType[t] ?? defaultUndefinedKey;
  return $t(`workJournal-eventType-${key}-label`);
}

// $t("workJournal-eventAction-Unknown-label", {$: "Unknown"})
// $t("workJournal-eventAction-Regular-label", {$: "Standard"})
// $t("workJournal-eventAction-Correction-label", {$: "Correction"})
// $t("workJournal-eventAction-Cancellation-label", {$: "Cancellation"})
// $t("workJournal-eventAction-Subsequent-label", {$: "Subsequent"})
export function translateWorkJournalRecordEventAction(t: WorkJournalRecordEventAction): string {
  const key = WorkJournalRecordEventAction[t] ?? defaultUndefinedKey;
  return $t(`workJournal-eventAction-${key}-label`);
}

// $t("boolean-true-label", {$: "True"})
// $t("boolean-false-label", {$: "False"})
export function translateBoolen(b: boolean) {
  return b ? $t("boolean-true-label") : $t("boolean-false-label");
}

// $t("material-origin-Unknown-label", {$: "Unknown"})
// $t("material-origin-VirginNonRenewable-label", {$: "Virgin non renewable"})
// $t("material-origin-VirginRenewable-label", {$: "Virgin renewable"})
// $t("material-origin-NonVirgin-label", {$: "Non virgin"})
export function translateMaterialOrigin(o: MaterialOrigin) {
  const key = MaterialOrigin[o] ?? MaterialOrigin[MaterialOrigin.Unknown];
  return $t(`material-origin-${key}-label`);
}

// $t("quotation-type-Unknown-label", {$: "Unknown"})
// $t("quotation-type-Quotation-label", {$: "Quotation"})
// $t("quotation-type-Offer-label", {$: "Offer"})
// $t("quotation-type-Estimation-label", {$: "Estimation"})
// $t("quotation-type-Actual-label", {$: "Actual"})
export function translateQuotationType(q: QuotationType): string {
  const key = QuotationType[q] ?? QuotationType[QuotationType.Unknown];
  return $t(`quotation-type-${key}-label`);
}

// $t("resourceCapacity-group-Supply-label", {$: "Supply"})
// $t("resourceCapacity-group-Demand-label", {$: "Demand"})
export function translateResourceCapacityGroup(q: ResourceCapacityGroup): string {
  const key = ResourceCapacityGroup[q] ?? ResourceCapacityGroup[ResourceCapacityGroup.Supply];
  return $t(`resourceCapacity-group-${key}-label`);
}

// $t("resourceCapacity-entrySource-Unknown-label", {$: "Unknown"})
// $t("resourceCapacity-entrySource-Calendar-label", {$: "Calendar"})
// $t("resourceCapacity-entrySource-AvailabilityRule-label", {$: "Availability rule"})
// $t("resourceCapacity-entrySource-PlanningBoard-label", {$: "Planning Board"})
// $t("resourceCapacity-entrySource-Shift-label", {$: "Shift"})
// $t("resourceCapacity-entrySource-Scheduling-label", {$: "Scheduling"})
// $t("resourceCapacity-entrySource-Reservation-label", {$: "Reservation"})
// $t("resourceCapacity-entrySource-Execution-label", {$: "Execution"})
// $t("resourceCapacity-entrySource-ExecutionResource-label", {$: "Execution Resource"})
// $t("resourceCapacity-entrySource-Balancing-label", {$: "Balancing"})
// $t("resourceCapacity-entrySource-ElectricityConsumption-label", {$: "Electricity Consumption"})
// $t("resourceCapacity-entrySource-Import-label", {$: "Import"})
// $t("resourceCapacity-entrySource-ManualEntry-label", {$: "Manual Entry"})
// $t("resourceCapacity-entrySource-DefaultInitialValue-label", {$: "Initial Value"})
export function translateResourceCapacityEntrySource(q: ResourceCapacityEntrySource): string {
  const key = ResourceCapacityEntrySource[q] ?? ResourceCapacityEntrySource[ResourceCapacityEntrySource.Unknown];
  return $t(`resourceCapacity-entrySource-${key}-label`);
}

// $t("businessAction-status-Waiting-label", {$: "Waiting"})
// $t("businessAction-status-Running-label", {$: "Running"})
// $t("businessAction-status-Finished-label", {$: "Finished"})
export function translateBusinessActionStatusType(q: BusinessActionStatus): string {
  const key = BusinessActionStatus[q] ?? BusinessActionStatus[BusinessActionStatus.Waiting];
  return $t(`businessAction-status-${key}-label`);
}

// $t("userNotice-status-Created-label", {$: "To do"})
// $t("userNotice-status-InProgress-label", {$: "In Progress"})
// $t("userNotice-status-ActionTaken-label", {$: "Done"})
// $t("userNotice-status-Acknowledged-label", {$: "Acknowledged"})
// $t("userNotice-status-Archived-label", {$: "Archived"})
export function translateUserNoticeStatus(q: UserNoticeStatus): string {
  const key = UserNoticeStatus[q] ?? UserNoticeStatus[UserNoticeStatus.Created];
  return $t(`userNotice-status-${key}-label`);
}

// $t("userNotice-type-Unknown-label", {$: "Unknown"})
// $t("userNotice-type-QuotationPricing-label", {$: "Selection of suppliers for pricing"})
export function translateUserNoticeType(q: UserNoticeType): string {
  const key = UserNoticeType[q] ?? UserNoticeType[UserNoticeType.Unknown];
  return $t(`userNotice-type-${key}-label`);
}

// $t("businessAction-resultStatus-Unknown-label", {$: "Unknown"})
// $t("businessAction-resultStatus-Success-label", {$: "Success"})
// $t("businessAction-resultStatus-Warning-label", {$: "Warning"})
// $t("businessAction-resultStatus-Error-label", {$: "Error"})
// $t("businessAction-resultStatus-PartialSuccess-label", {$: "Partial Success"})
export function translateBusinessActionResultStatusType(q: BusinessActionResultStatus): string {
  const key = BusinessActionResultStatus[q] ?? BusinessActionResultStatus[BusinessActionResultStatus.Unknown];
  return $t(`businessAction-resultStatus-${key}-label`);
}

// $t("businessAction-area-Scheduling-label", {$: "Scheduling"})
// $t("businessAction-area-Planning-label", {$: "Planning"})
// $t("businessAction-area-BasicData-label", {$: "Basic Data"})
// $t("businessAction-area-Execution-label", {$: "Execution"})
// $t("businessAction-area-Export-label", {$: "Export"})
export function translateBusinessActionAreaType(q: BusinessActionArea): string {
  const key = BusinessActionArea[q] ?? BusinessActionArea[BusinessActionArea.Scheduling];
  return $t(`businessAction-area-${key}-label`);
}

// $t("communicationItem-status-Unknown-label", {$: "Unknown"})
// $t("communicationItem-status-Draft-label", {$: "Draft"})
// $t("communicationItem-status-Planned-label", {$: "Planned"})
// $t("communicationItem-status-Sent-label", {$: "Sent"})
// $t("communicationItem-status-Failed-label", {$: "Failed"})
// $t("communicationItem-status-Delivered-label", {$: "Delivered"})
// $t("communicationItem-status-Read-label", {$: "Read"})
// $t("communicationItem-status-Responded-label", {$: "Responded"})
// $t("communicationItem-status-Acknowledged-label", {$: "Acknowledged"})
// $t("communicationItem-status-Cancelled-label", {$: "Cancelled"})
export function translateCommunicationItemStatus(s: CommunicationStatus): string {
  const key = CommunicationStatus[s] ?? CommunicationStatus[CommunicationStatus.Unknown];
  return $t(`communicationItem-status-${key}-label`);
}

// $t("communicationItem-communicationType-Unknown-label", {$: "Unknown"})
// $t("communicationItem-communicationType-Sms-label", {$: "Sms"})
// $t("communicationItem-communicationType-Email-label", {$: "Email"})
// $t("communicationItem-communicationType-PushNotification-label", {$: "Push Notification"})
export function translateCommunicationItemCommunicationType(ct: CommunicationType): string {
  const key = CommunicationType[ct] ?? CommunicationType[CommunicationType.Unknown];
  return $t(`communicationItem-communicationType-${key}-label`);
}

// $t("gantt-timeSectionAggregation-None-label", {$: "None"})
// $t("gantt-timeSectionAggregation-Hourly-label", {$: "Hourly"})
// $t("gantt-timeSectionAggregation-Daily-label", {$: "Daily"})
// $t("gantt-timeSectionAggregation-Weekly-label", {$: "Weekly"})
// $t("gantt-timeSectionAggregation-Monthly-label", {$: "Monthly"})
export function translateGanttTimeSectionAggregation(ct: GanttTimeSectionAggregation): string {
  const key = GanttTimeSectionAggregation[ct] ?? GanttTimeSectionAggregation[GanttTimeSectionAggregation.None];
  return $t(`gantt-timeSectionAggregation-${key}-label`);
}

// $t("time-periodAggregationUnits-Yearly-label", {$: "Yearly"})
// $t("time-periodAggregationUnits-Quarterly-label", {$: "Quarterly"})
// $t("time-periodAggregationUnits-Monthly-label", {$: "Monthly"})
// $t("time-periodAggregationUnits-Weekly-label", {$: "Weekly"})
// $t("time-periodAggregationUnits-Daily-label", {$: "Daily"})
// $t("time-periodAggregationUnits-Hourly-label", {$: "Hourly"})
export function translatePeriodAggregationUnits(ct: PeriodAggregationUnits): string {
  const key = PeriodAggregationUnits[ct] ?? GanttTimeSectionAggregation[PeriodAggregationUnits.Daily];
  return $t(`time-periodAggregationUnits-${key}-label`);
}

// $t("materialDemand-quantityType-Initial-label", {$: "Initial"})
// $t("materialDemand-quantityType-Supply-label", {$: "Supply"})
// $t("materialDemand-quantityType-Demand-label", {$: "Demand"})
// $t("materialDemand-quantityType-Balance-label", {$: "Balance"})
export function translateMaterialDemandQuantityType(mu: MaterialDemandQuantityType): string {
  if (typeof mu === "number") {
    const key = MaterialDemandQuantityType[mu];
    return $t(`materialDemand-quantityType-${key}-label`);
  }
  return "";
}

// just to have it in translations
// $t("keyPerformanceIndicator-name-Delays-label", {$: "Delays"})
// $t("keyPerformanceIndicator-name-Setups-label", {$: "Setups"})
// $t("keyPerformanceIndicator-name-RenewableEnergy-label", {$: "RenewableEnergy"})
// $t("product-dashboard-custom-button-label", {$: "Picking"})
